import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LayoutActions, OperadorasActions } from '@core/store/actions';
import { SIDEBAR_MENU } from '@core/menu';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { UserProfileSelectors, OperadorasSelectors } from '@core/store/selectors';

@Injectable()
export class LayoutEffects {
  updateMenuLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperadorasActions.changeSelectedOperadoraWorkspace),
      withLatestFrom(
        this.store.select(UserProfileSelectors.selectNivelAcesso),
        this.store.select(UserProfileSelectors.selectUserIdssProfile),
        this.store.select(OperadorasSelectors.selectWorkspaceOperadora),
      ),
      map(([{ cd_registro_ans }, nivelAcesso, perfil, operadora]) => {
        const menu = SIDEBAR_MENU.map(parent => {
          const items = parent.items.map(item => {
            let visible = item.visible;

            if (item.label === 'Correção de Guias') {
              if (nivelAcesso === 'A') {
                visible = true;
              } else if (nivelAcesso === 'O' && perfil && operadora) {
                const operadoraVinculada = perfil.operadoras_vinculadas?.find(
                  op => op.cd_registro_ans === operadora.cd_registro_ans,
                );
                visible = !!operadoraVinculada?.modulos_liberados?.find(mod => mod.cd_modulo === 'PREMIUM');
              } else {
                visible = false;
              }
            }

            if (!item.routerLink) {
              return { ...item, visible };
            }

            const routerLink = [item.routerLink[0].replace('${regans}', cd_registro_ans)];
            return { ...item, routerLink, visible };
          });
          return { ...parent, items };
        });

        return LayoutActions.updateMenuLinks({ menu });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private titleService: Title,
  ) {}
}
