import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CriticaGuia,
  CriticaItemGuia,
  CriticaResumo,
  JsonPreGerado,
  LinhaResumoGuia,
  LinhaResumoItemGuia,
  OperadoraCompetenciaParams,
} from '@core/types';
import { environment } from 'environments/environment';
import { Api } from '../api';
import {
  ArquivoGerado,
  ArquivoPostado,
  ArquivoSituacao,
  CnsRepetidos,
  CnsRepetidosGuias,
  ComparativoTissDiops,
  ConsultasEletivas,
  CpfRepetidos,
  CpfRepetidosGuias,
  CriticaOperadoraIntermediaria,
  RejeitadosReceitaFederal,
  DispersaoUrgenciaEmergencia,
  DespesasAssistenciais,
  DespesasAssistenciaisDetalhe,
  DetalheBeneficiario,
  DetalheGuia,
  DetalhePrestador,
  FornecimentoDireto,
  FornecimentoDiretoDetalhe,
  GuiaIndicadorIdss,
  GuiaMedicaoIdss,
  GuiasVinculadas,
  HistoricoIdss,
  JSON_PRE_GERADO_TIPO,
  JsonPreGeradoLinhas,
  JsonPreGeradoResponse,
  LancamentosGuia,
  ListagemGuia,
  ListagemGuiaParams,
  LoteXte,
  LotesXtePendentes,
  MedicaoIdss,
  MedicaoUpdate,
  OperadoraParametros,
  OperadoraPlanoSaude,
  PAGE_PARAMS_DEFAULT,
  PacoteProcedimentoProprio,
  PageParams,
  PontuacaoDimensao,
  PreVerificacaoRequest,
  PreVerificacaoResponse,
  ProjecaoRazaoTissDiops,
  RankingCbosConsultasEletivas,
  RankingMedicosConsultasEletivas,
  RankingMedicosConsultasEletivasGuias,
  Response,
  ResumoGuiasOutrasOps,
  ResumoGuiasRedePropria,
  ResumoHistoricoCriticas,
  ResumoValoresDiops,
  RetornoGuia,
  RetornoItem,
  RetornoListagem,
  SimuladoIdss,
  TipoArquivo,
  Total,
  UploadMetadata,
  ValorPreestabelecido,
} from './model';
import { CorrecaoGuias, CorrecaoGuiasLog } from './model/correcao-guias';

/**
 *  ORDS-API para Simulador
 */
@Injectable({ providedIn: 'root' })
export class SimuladorApi extends Api {
  constructor(http: HttpClient) {
    super(`${environment.API_ORDS_BASE_PATH}/simulador`, http);
  }

  fetchParametorsOperadora(regans: string) {
    const path = `/parametros/operadora/${regans}`;
    return this.get<OperadoraParametros>(path);
  }

  /**
   * Criação de um simulado.
   */
  createSimulado(regans: string, simulado: SimuladoIdss) {
    const path = `/simulados/operadora/${regans}/simulado`;
    return this.post<Response<SimuladoIdss>>(path, simulado);
  }

  /**
   * Edição de um simulado.
   */
  updateSimulado(regans: string, cd_simulado: number, simulado: SimuladoIdss) {
    const path = `/simulados/operadora/${regans}/simulado/${cd_simulado}`;
    return this.put<Response<SimuladoIdss>>(path, simulado);
  }

  deleteSimulado(regans: string, cd_simulado: number) {
    const path = `/simulados/operadora/${regans}/simulado/${cd_simulado}`;
    return this.delete(path);
  }

  /**
   * Listagem dos simulados de uma operadora.
   */
  fetchSimuladosOperadora(regans: string | number) {
    const path = `/simulados/operadora/${regans}`;
    return this.get<Response<SimuladoIdss>>(path);
  }

  /**
   * Busca o simulado de uma operadora pelo código do simulado.
   */
  fetchSimuladoOperadoraById(regans: string | number, cd_simulado: string | number) {
    const path = `/simulados/operadora/${regans}/simulado/${cd_simulado}`;
    return this.get<Response<SimuladoIdss>>(path);
  }

  /**
   * Listagem dos valores/medicoes de um determinado simulado
   */
  fetchMedicoesSimuladoOperadoraById(regans: string | number, cd_simulado: string | number) {
    const path = `/simulados/operadora/${regans}/simulado/${cd_simulado}/medicoes`;
    return this.get<Response<MedicaoIdss>>(path);
  }

  /**
   * Edição de uma Medição,
   */
  updateMedicao(
    regans: string | number,
    cd_simulado: string | number,
    cd_medicao: string | number,
    medicao: MedicaoIdss,
  ) {
    const path = `/simulados/operadora/${regans}/simulado/${cd_simulado}/medicoes/${cd_medicao}`;
    return this.put<Response<MedicaoUpdate>>(path, medicao);
  }

  /**
   * Listagem de guias da Medição
   */
  fetchGuiasMedicao(filter: {
    regans: string | number;
    cd_simulado: string | number;
    cd_medicao: string | number;
    limit?: number;
  }) {
    const { regans, cd_simulado, cd_medicao, limit } = filter;
    const path = `/simulados/operadora/${regans}/simulado/${cd_simulado}/medicoes/${cd_medicao}/guias`;
    return this.get<Response<GuiaMedicaoIdss>>(path, { limit });
  }

  /**
   * Listagem de guias do Indicador.
   */
  fetchGuiasIndicador(
    filter: OperadoraCompetenciaParams & {
      cd_simulado: string | number;
      cd_indicador: string | number;
    },
  ) {
    const { regans, cd_simulado, cd_indicador, ...range } = filter;
    const path = `/simulados/operadora/${regans}/simulado/${cd_simulado}/medicoes/${cd_indicador}/guias-apuracao`;
    return this.get<Response<GuiaIndicadorIdss>>(path, range);
  }

  /**
   * Cálculo de um simulado.
   */
  calcularSimulado(regans: string | number, cd_simulado: number) {
    const path = `/simulados/operadora/${regans}/simulado/${cd_simulado}/calculo`;
    return this.post<Response<void>>(path);
  }

  /**
   * Busca dos dados com JSON pré-gerado.
   */
  fetchDadosJsonPreGerado<T extends JsonPreGeradoLinhas>(
    regans: string | number,
    consulta: JSON_PRE_GERADO_TIPO,
    filtro: { anobase?: number; competencia?: string; valorcampochave?: number },
  ) {
    const path = `/consuta-json-pre-gerado/operadora/${regans}/consulta/${consulta}`;
    return this.get<Response<JsonPreGeradoResponse<T>>>(path, filtro);
  }

  /**
   * Busca o histórico do IDSS de uma operadora com dados privados
   *
   * @param regans código da operadora
   */
  fetchSimuladorHistoricoIdss(regans: string) {
    if (!regans) {
      throw new Error('Required parameter regans was null when calling fetchSimuladorHistoricoIdss.');
    }
    return this.get<Response<HistoricoIdss>>(`/idss/historico/operadora/${regans}`);
  }

  /**
   * Busca as pontuções das dimensões do IDSS de uma operadora com dados privados
   *
   * @param regans código da operadora
   */
  fetchPontuacaoDimensao(regans: string) {
    if (!regans) {
      throw new Error('Required parameter regans was null when calling fetchPontuacaoDimensao.');
    }
    return this.get<Response<PontuacaoDimensao>>(`/idss/pontuacao-dimensao/operadora/${regans}`);
  }

  /**
   * Busca os dados do Resumo de Guias.
   */
  // fetchDadosResumoGuiasCubo({ regans, ...params }: OperadoraCompetenciaParams) {
  //   return this.get<Response<ResumoGuia>(`/guias/resumo-guias/operadora/${regans}`, { ...params });
  // }

  fetchDadosResumoGuiasCuboJsonPreGerado({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<JsonPreGerado<LinhaResumoGuia>>>(
      `/consuta-json-pre-gerado/operadora/${regans}/consulta/MON_CR_GUIA_RESUMO_V202403`,
      { ...params },
    );
  }

  /**
   * Busca os dados do Resumo de Itens de Guias.
   */
  // fetchDadosResumoItensGuiasCubo({ regans, ...params }: OperadoraCompetenciaParams) {
  //   return this.get<Response<ResumoItemGuia>>(`/guias/resumo-itens-guias/operadora/${regans}`, {
  //     ...params,
  //   });
  // }

  fetchDadosResumoItensGuiasCuboJsonPreGerado({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<JsonPreGerado<LinhaResumoItemGuia>>>(
      `/consuta-json-pre-gerado/operadora/${regans}/consulta/MON_CR_GUIA_ITEM_RESUMO_V202403`,
      { ...params },
    );
  }

  fetchDadosCriticaResumo({ regans, ...params }: OperadoraCompetenciaParams & { tp_seq_lancto?: string }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<CriticaResumo>>(`/guias/criticas/resumo-evolucao/operadora/${regans}`, { ...trimmed });
  }

  fetchDadosCriticaListagemGuias(
    { regans, ...params }: OperadoraCompetenciaParams & { codcritica: number; tp_seq_lancto?: string },
    page: PageParams = PAGE_PARAMS_DEFAULT,
  ) {
    const trimmed = this.trimParams(params);
    return this.get<Response<CriticaGuia>>(`/guias/criticas/listagem-guias/operadora/${regans}`, {
      ...page,
      ...trimmed,
    });
  }

  fetchDadosCriticaListagemItensGuias(
    { regans, ...params }: OperadoraCompetenciaParams & { codcritica: number; tp_seq_lancto?: string },
    page: PageParams = PAGE_PARAMS_DEFAULT,
  ) {
    const trimmed = this.trimParams(params);
    return this.get<Response<CriticaItemGuia>>(`/guias/criticas/listagem-itens-guias/operadora/${regans}`, {
      ...page,
      ...trimmed,
    });
  }

  fetchDadosResumoHistoricoCriticas({
    regans,
    ...params
  }: {
    regans: string;
    nr_anobase: number;
    critica?: string;
    tp_impacto?: string;
    competencia?: string;
    competencia_final?: string;
  }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<ResumoHistoricoCriticas>>(`/guias/historico/operadora/${regans}`, trimmed);
  }

  fetchListagemGuias({ regans, ...params }: ListagemGuiaParams, page: PageParams = PAGE_PARAMS_DEFAULT) {
    const trimmed = this.trimParams(params);
    return this.get<Response<ListagemGuia>>(`/guias/listagem-guias/operadora/${regans}`, {
      ...trimmed,
      ...page,
    });
  }

  fetchLancamentosGuias({ regans, ...params }: OperadoraCompetenciaParams) {
    const trimmed = this.trimParams(params);
    return this.get<Response<LancamentosGuia>>(`/guias/lancamentos-guias/operadora/${regans}`, trimmed);
  }

  countListagemGuias({ regans, ...params }: ListagemGuiaParams) {
    const trimmed = this.trimParams(params);
    return this.get<Total>(`/guias/listagem-guias/operadora/${regans}/conta_total`, trimmed);
  }

  fetchDetalhesGuiaLancamento({ regans, nr_guia_monitor }: { regans: string; nr_guia_monitor: string }) {
    return this.get<Response<DetalheGuia>>(`/guias/operadora/${regans}/lancamentos/${nr_guia_monitor}`);
  }

  fetchDetalhesBeneficiario({ regans, nr_guia_monitor }: { regans: string; nr_guia_monitor: string }) {
    return this.get<Response<DetalheBeneficiario>>(
      `/beneficiarios/operadora/${regans}/beneficiario-guia/${nr_guia_monitor}`,
    );
  }

  fetchDetalhesPrestador({
    regans,
    nr_cpf_cnpj_exec,
    ...params
  }: {
    regans: string;
    nr_cpf_cnpj_exec: string;
    cd_guia_monitor: string;
    nr_cnes_exec?: string;
    nr_registro_plano?: string;
  }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<DetalhePrestador>>(
      `/prestadores/operadora/${regans}/prestador-guia/${nr_cpf_cnpj_exec}`,
      trimmed,
    );
  }

  fetchComparativoTissDiops({ regans, competencia }: { regans: string; competencia: number }) {
    return this.get<Response<ComparativoTissDiops>>(`/remuneracao/diops/operadora/${regans}`, {
      nr_ano_base: competencia,
    });
  }

  fetchResumoValoresDiops({ regans, competencia }: { regans: string; competencia: number }) {
    return this.get<Response<ResumoValoresDiops>>(`/remuneracao/valor-informado-tiss/operadora/${regans}`, {
      nr_ano_base: competencia,
    });
  }

  fetchProjecaoRazaoTissDiops({ regans, competencia }: { regans: string; competencia: number }) {
    return this.get<Response<ProjecaoRazaoTissDiops>>(`/remuneracao/razao-tiss-diops/operadora/${regans}`, {
      nr_ano_base: competencia,
    });
  }

  fetchProjecaoRazaoTissDiopsProtocolo({ regans, competencia }: { regans: string; competencia: number }) {
    return this.get<Response<ProjecaoRazaoTissDiops>>(`/remuneracao/razao-tiss-diops-protocolo/operadora/${regans}`, {
      nr_ano_base: competencia,
    });
  }

  fetchArquivosGerados(regans: string, dt_inclusao_desde: string, page: PageParams = PAGE_PARAMS_DEFAULT) {
    const params = this.trimParams({ dt_inclusao_desde, ...page });
    return this.get<Response<ArquivoGerado>>(`/arquivos/operadora/${regans}/downloads`, params);
  }

  downloadArquivoGerado(regans: string, arquivo: ArquivoGerado) {
    return this.http.get(`${this.basePath}/arquivos/operadora/${regans}/downloads/${arquivo.cd_arquivo}`, {
      responseType: 'blob',
    });
  }

  fetchArquivosOperadora(
    regans: string,
    tp_arquivo: TipoArquivo,
    dt_inclusao_desde: string,
    page: PageParams = PAGE_PARAMS_DEFAULT,
  ) {
    const params = this.trimParams({
      tp_arquivo,
      dt_inclusao_desde,
      ...page,
    });
    return this.get<Response<ArquivoPostado>>(`/arquivos/operadora/${regans}`, params);
  }

  checkPreVerificacaoPostagem({ regans, ...metadata }: PreVerificacaoRequest) {
    return this.post<PreVerificacaoResponse>(`/arquivos/pre-verificacao-postagem/operadora/${regans}`, metadata);
  }

  sendUploadMetadata(regans: string, metadata: UploadMetadata) {
    return this.post<void>(`/arquivos/operadora/${regans}/metadados`, metadata);
  }

  fetchLotesXte({ regans, ...params }: OperadoraCompetenciaParams & { dt_inclusao_desde: string }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<LoteXte>>(`/arquivos/operadora/${regans}/lotes-xte`, trimmed);
  }

  downloadLoteXte(regans: string, arquivo: LoteXte) {
    return this.http.get(
      `${this.basePath}/arquivos/operadora/${regans}/lotes-xte/downloads-log/${arquivo.cd_arquivo}`,
      {
        responseType: 'blob',
      },
    );
  }

  fetchArquivosSituacao(regans: string, anobase: number) {
    return this.get<ArquivoSituacao>(`/arquivos/operadora/${regans}/situacao/${anobase}`);
  }

  fetchCorrecaoGuias(regans: string) {
    return this.get<Response<CorrecaoGuias>>(`/guias/lancamentos-guias/operadora/${regans}/retornos-ans`);
  }

  fetchCorrecaoGuiasLog(regans: string, cd_guia_monitor: string) {
    return this.get<Response<CorrecaoGuiasLog>>(`/guias/lancamentos-guias/operadora/${regans}/${cd_guia_monitor}/log`);
  }

  fetchRetornosGuias({ regans, ...params }: OperadoraCompetenciaParams & { tp_lancto_pendente: string }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<RetornoGuia>>(`/guias/retornos-ans/guias/operadora/${regans}`, trimmed);
  }

  fetchRetornosItens({ regans, ...params }: OperadoraCompetenciaParams & { tp_lancto_pendente: string }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<RetornoItem>>(`/guias/retornos-ans/itens/operadora/${regans}`, trimmed);
  }

  fetchRetornoListagem({
    regans,
    ...params
  }: OperadoraCompetenciaParams & { nr_campo: number; cd_motivo_glosa: number; tp_lancto_pendente: string }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<RetornoListagem>>(`/guias/retornos-ans/listagem/operadora/${regans}`, trimmed);
  }

  fetchRankingCbosConsultasEletivas({
    regans,
    ...params
  }: OperadoraCompetenciaParams & {
    ano_realizacao: number;
    id_faixa_etaria_consulta: number;
    nr_cnpj_cpf_exec?: string;
    tp_rede?: string;
  }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<RankingCbosConsultasEletivas>>(
      `/analises/ranking-cbo-consultas/operadora/${regans}`,
      trimmed,
    );
  }

  fetchResumoGuiasRedePropria({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<ResumoGuiasRedePropria>>(`/analises/resumo-guias-rede-propria/operadora/${regans}`, {
      ...params,
    });
  }

  fetchResumoGuiasOutrasOps({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<ResumoGuiasOutrasOps>>(`/analises/resumo-guias-outras-ops/operadora/${regans}`, {
      ...params,
    });
  }

  fetchConsultasMedicasEletivas({
    regans,
    ...params
  }: OperadoraCompetenciaParams & { ano_realizacao: number; id_faixa_etaria: number; ib_critica_pendente: number }) {
    return this.get<Response<ConsultasEletivas>>(`/analises/consultas-eletivas/operadora/${regans}`, {
      ...params,
    });
  }

  fetchRankingMedicosConsultasEletivas({
    regans,
    ...params
  }: OperadoraCompetenciaParams & {
    ano_realizacao: number;
    id_faixa_etaria: number;
    nr_cnpj_cpf_exec?: string;
    tp_rede?: string;
  }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<RankingMedicosConsultasEletivas>>(
      `/analises/ranking-medicos-consultas-eletivas/operadora/${regans}`,
      trimmed,
    );
  }

  fetchRankingMedicosConsultasEletivasListaGuias({
    regans,
    ...params
  }: OperadoraCompetenciaParams & {
    ano_realizacao: number;
    id_faixa_etaria: number;
    cd_guia_monitor?: string;
    nr_cnpj_cpf_exec?: string;
    tp_rede?: string;
  }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<RankingMedicosConsultasEletivasGuias>>(
      `/analises/ranking-medicos-consultas-eletivas/operadora/${regans}/listagem-guias`,
      {
        ...trimmed,
      },
    );
  }

  fetchCnsRepetido({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<CnsRepetidos>>(`/analises/cns-repetido/operadora/${regans}`, {
      ...params,
    });
  }

  fetchCnsRepetidoListaGuias({ regans, ...params }: OperadoraCompetenciaParams & { cd_guia_monitor: string }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<CnsRepetidosGuias>>(`/analises/cns-repetido/operadora/${regans}/listagem-guias`, {
      ...trimmed,
    });
  }

  fetchCpfRepetido({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<CpfRepetidos>>(`/analises/cpf-repetido/operadora/${regans}`, {
      ...params,
    });
  }

  fetchCpfRepetidoListaGuias({ regans, ...params }: OperadoraCompetenciaParams & { cd_guia_monitor: string }) {
    const trimmed = this.trimParams(params);
    return this.get<Response<CpfRepetidosGuias>>(`/analises/cpf-repetido/operadora/${regans}/listagem-guias`, {
      ...trimmed,
    });
  }

  fetchCriticasOperadoraIntermediaria({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<CriticaOperadoraIntermediaria>>(
      `/analises/criticas-operadora-intermediaria/operadora/${regans}`,
      {
        ...params,
      },
    );
  }

  fetchRejeitadosReceitaFederal({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<RejeitadosReceitaFederal>>(`/analises/rejeitados-receita-federal/operadora/${regans}`, {
      ...params,
    });
  }

  fetchDispersaoUrgenciaEmergencia({ regans }) {
    return this.get<Response<DispersaoUrgenciaEmergencia>>(
      `/analises/dispersao-urgencia-emergencia/operadora/${regans}`,
    );
  }

  fetchValorPreestabelecido({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<ValorPreestabelecido>>(
      `/remuneracao/outras-remuneracoes/valor-preestabelecido/operadora/${regans}`,
      params,
    );
  }

  fetchValorPreestabelecidoGuiasVinculadas(
    regans: string,
    nr_contrato_valor_pre: string,
    nr_competencia_cobertura: string,
  ) {
    return this.get<Response<GuiasVinculadas>>(
      `/remuneracao/outras-remuneracoes/valor-preestabelecido/operadora/${regans}/guias-vinculadas/contrato/${nr_contrato_valor_pre}`,
      { nr_competencia_cobertura },
    );
  }

  fetchFornecimentoDireto({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<FornecimentoDireto>>(
      `/remuneracao/outras-remuneracoes/operadora/${regans}/fornecimento-direto/lancamentos`,
      params,
    );
  }

  fetchFornecimentoDiretoDetalhe(regans: string, cd_guia_monitor: string) {
    return this.get<FornecimentoDiretoDetalhe>(
      `/remuneracao/outras-remuneracoes/operadora/${regans}/fornecimento-direto/lancamentos/${cd_guia_monitor}`,
    );
  }

  fetchDespesasAssistenciais({ regans, ...params }: OperadoraCompetenciaParams) {
    return this.get<Response<DespesasAssistenciais>>(
      `/remuneracao/outras-remuneracoes/operadora/${regans}/outras-despesas-assistenciais/lancamentos`,
      params,
    );
  }

  fetchDespesasAssistenciaisDetalhe(regans: string, cd_guia_monitor: string) {
    return this.get<DespesasAssistenciaisDetalhe>(
      `/remuneracao/outras-remuneracoes/operadora/${regans}/outras-despesas-assistenciais/lancamentos/${cd_guia_monitor}`,
    );
  }

  fetchOperadoraPlanosSaude(regans: string) {
    return this.get<Response<OperadoraPlanoSaude>>(`/operadoras/operadora/${regans}/planos-de-saude`);
  }

  fetchPacotesProcedimentosProprios(regans: string) {
    return this.get<Response<PacoteProcedimentoProprio>>(
      `/operadoras/operadora/${regans}/pacotes-procedimentos-proprios`,
    );
  }

  createPacotesProcedimentosProprios(regans: string, pacote: PacoteProcedimentoProprio) {
    return this.post<void>(`/operadoras/operadora/${regans}/pacotes-procedimentos-proprios`, pacote);
  }

  updatePacotesProcedimentosProprios(regans: string, cd_item_proprio: string, pacote: PacoteProcedimentoProprio) {
    return this.put<void>(`/operadoras/operadora/${regans}/pacotes-procedimentos-proprios/${cd_item_proprio}`, pacote);
  }

  deletePacotesProcedimentosProprios(regans: string, cd_item_proprio: string, dt_alteracao: string) {
    return this.delete(`/operadoras/operadora/${regans}/pacotes-procedimentos-proprios/${cd_item_proprio}`, {
      dt_alteracao,
    });
  }

  fetchLotesXtePendentes(regans: string, anobase: number) {
    return this.get<Response<LotesXtePendentes>>(`/arquivos/operadora/${regans}/lotes-xte-pendentes/${anobase}`);
  }

  updateCorrecaoGuia(
    regans: string,
    cd_guia_monitor: string,
    data: {
      nr_cpf_beneficiario: string;
      nr_cns: string;
      id_sexo: string;
      dt_nascimento: string | number;
      alterado?: boolean;
    },
  ) {
    const path = `/guias/lancamentos-guias/operadora/${regans}/${cd_guia_monitor}`;
    return this.put(path, data);
  }
}
