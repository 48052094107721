import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUserProfile from '@core/store/reducers/user-profile.reducers';

export const selectUserProfile = createFeatureSelector<fromUserProfile.State>(fromUserProfile.featureKey);
export const selectUserKcProfile = createSelector(selectUserProfile, state => state.kc);
export const selectUserIdssProfile = createSelector(selectUserProfile, state => state.idss);
export const selectProfileIncomplete = createSelector(selectUserProfile, state => state.isIncomplete);
export const selectNivelAcesso = createSelector(selectUserProfile, state => state.nivelAcesso);
export const selectNivelAcessoOperadora = createSelector(selectUserProfile, state => state.nivelAcessoOperadora);
export const selectIsAdmin = createSelector(selectUserProfile, state => state.nivelAcesso == 'A');
export const selectOperadorasVinculadas = createSelector(selectUserProfile, state => state.operadorasVinculadas);
export const selectOperadorasCoordenadas = createSelector(selectUserProfile, state => state.operadorasCoordenadas);
