import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CNPJPipe } from './cnpj.pipe';
import { CompetenciaPipe } from './competencia.pipe';
import { CpfCnpjPipe } from './cpf-ou-cnpj.pipe';
import { CepPipe } from './cep.pipe';
import { CPFPipe } from './cpf.pipe';
import { PontuacaoPipe } from './pontuacao.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { BrlCurrencyPipe } from './brl-currency.pipe';

const PIPES = [CPFPipe, CNPJPipe, PontuacaoPipe, SafeHtmlPipe, CompetenciaPipe, CpfCnpjPipe, CepPipe, BrlCurrencyPipe];

const COMMON_PIPES = [DatePipe, DecimalPipe];

@NgModule({
  declarations: [PIPES],
  exports: [PIPES],
  imports: [CommonModule],
  providers: [PIPES, COMMON_PIPES],
})
export class BlendusPipesModule {}
