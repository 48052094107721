import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
@Pipe({
  name: 'brlCurrency',
})
export class BrlCurrencyPipe implements PipeTransform {
  @memo()
  transform(inputValue: any): string {
    if (!inputValue) {
      return '';
    }
    const numericValue = parseFloat(inputValue);
    if (isNaN(numericValue)) {
      return inputValue;
    }
    return numericValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}
