import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'cep',
})
export class CepPipe implements PipeTransform {
  @memo()
  transform(cepValue: any) {
    if (!cepValue) {
      return '';
    }

    const cep = cepValue.toString().replace(/[^\d]/g, '');
    return cep.replace(/^(\d{5})(\d{3})$/, '$1-$2');
  }
}
